<template>
  <div class="content m-t-24">
    <div class="content-col">
      <div class="view-box">
        <div class="view-box-title">清运统计
          <div style="float: right">
            <!--<a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentWeek')">近7日</a-button>
            <a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentWeek')">近30日</a-button>
            <a-button style="margin-left: 10px" type="primary" ghost shape="round" @click="dateClick('currentMonth')">本年</a-button>-->
            <a-radio-group default-value="currentMonth" size="small" button-style="solid">
              <a-radio-button value="currentWeek" @click="dateClick('currentWeek')">
                近7日
              </a-radio-button>
              <a-radio-button value="currentMonth" @click="dateClick('currentMonth')">
                近30日
              </a-radio-button>
              <!--              <a-radio-button value="currentYear" @click="typeClick('currentYear')">-->
              <!--                本年-->
              <!--              </a-radio-button>-->
            </a-radio-group>
          </div>
        </div>
      </div>
      <div class="view-content-box">
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('sb')">
          <div class="content" style="padding: 0;">
            <span class="card-title">报抽次数</span><br>
            <span class="card-value" style="font-size: 15px;">{{kpiData.totalTask?kpiData.totalTask:0}}<span class="unit"> 次</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('ts')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运趟数</span><br>
            <span class="card-value" style="font-size: 15px;">{{kpiData.totalLoop}}<span class="unit"> 趟</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('lc')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运里程</span><br>
            <span class="card-value" style="font-size: 15px;">{{Math.round(kpiData.totalMileage)}}<span class="unit"> km</span></span><br>
          </div>
        </div>
        <div class="data-card" style="margin-left: 0px;cursor: pointer" @click="onClick('ds')">
          <div class="content" style="padding: 0;">
            <span class="card-title">拉运吨数</span><br>
            <span class="card-value" style="font-size: 15px;">{{Math.round(kpiData.totalTon)}}<span class="unit"> 吨</span></span><br>
          </div>
        </div>
      </div>
      <div style="clear: both;margin-top: 10px">
        <div id="line" style="width: 100%;height: 335px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import echarts from 'echarts'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'

  export default {
    name: 'RightTwoLine',
    props: {
      time: Array
    },
    data () {
      return {
        charts: null,
        xAxisData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        data: [19, 23, 18, 22, 25, 24, 26, 21, 17, 24, 0, 0],
        seriesName: '次数',
        text: '报抽数量统计',
        reportData: [], //报抽数量统计曲线数据
        type: 'thirtyDays', //查询类型（近7日、近30日、当年）
        startTime: '',
        endTime: '',
        kpiData: {}
      }
    },
    mounted () {
      this.startTime = moment().subtract(30, 'days').format('YYYY-MM-DD')
      this.endTime = moment().format('YYYY-MM-DD')
      this.getRecordStatistics()
    },
    methods: {
      getRecordStatistics () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.dispatch_history_statistics,
          noTips: true,
          loading: 'no',
          data: {
            areaNo: ['22'],
            startTime: this.startTime,
            endTime: this.endTime
          },
          success: (data) => {
            this.kpiData = data.body[0]
          },
          error: () => {
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.csgz.statisticsApi.history_chart_detail,
          noTips: true,
          data: {
            codes: ['22'],
            type: this.type,
            startTime: this.startTime,
            endTime: this.endTime
          },
          loading: 'no',
          success: (data) => {
            this.xAxisData = []
            this.recordData = []
            this.reportData = []
            this.mileageData = []
            this.tonData = []
            data.body.forEach((e) => {
              this.xAxisData.push(e.dayStr)
              this.reportData.push(e.totalTask)
              this.mileageData.push(e.totalMileage)
              this.tonData.push(e.totalTon)
              this.recordData.push(e.totalLoop)
            })
            this.data = this.reportData
            this.initLine()
          },
          error: () => {
          }
        })
      },
      dateClick (type) {
        if (type === 'currentWeek') {
          this.startTime = moment().subtract(6, 'days').format('YYYY-MM-DD')
        } else if (type === 'currentMonth') {
          this.startTime = moment().subtract(29, 'days').format('YYYY-MM-DD')
        }
        this.endTime = moment().format('YYYY-MM-DD')
        this.getRecordStatistics()
      },
      initLine () {
        this.charts = echarts.init(document.getElementById('line'))
        this.charts.setOption({
          title: {
            show: true,
            text: this.text,
            textStyle: {
              color: 'rgb(246,255,253)',
              fontSize: '12'
            },
            top: 20,
            left: '40%'
          },
          grid: {
            left: '7%',
            right: 20,
            bottom: 30
          },
          xAxis: {
            show: true,
            type: 'category',   // 还有其他的type，可以去官网喵两眼哦
            data: this.xAxisData,   // x轴数据
            axisLine: {
              lineStyle: {
                color: 'rgba(250,255,248,0.77)'
              }
            }
          },
          yAxis: {
            min: '0',
            show: true,
            type: 'value',
            axisLine: {
              lineStyle: {
                color: 'rgba(250,255,248,0.77)'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(103,103,103,0.95)',
                type: 'dashed'
              }
            }
          },
          tooltip: {
            trigger: 'axis'   // axis   item   none三个值
          },
          series: [
            {
              name: this.seriesName,
              data: this.data,
              type: 'line',
              lineStyle: {
                color: 'rgba(15,204,255,0.77)'
              }
            }
          ]
        })
      },
      onClick (type) {
        if (type === 'sb') {
          this.data = this.reportData
          this.seriesName = '次数'
          this.text = '报抽次数统计'
        }
        if (type === 'lc') {
          this.data = this.mileageData
          this.seriesName = '公里'
          this.text = '拉运里程统计'
        }
        if (type === 'ds') {
          this.data = this.tonData
          this.seriesName = '吨'
          this.text = '拉运吨数统计'
        }
        if (type === 'ts') {
          this.data = this.recordData
          this.seriesName = '趟'
          this.text = '拉运趟数统计'
        }
        this.initLine()
      }
    }

  }
</script>
